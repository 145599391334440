import dayjs from 'dayjs';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import qs from 'qs';

import { wrapFetch, wrapAuthFetch, wrapFetchSSO } from 'util/api';
import { useRedux } from 'util/hook/redux';
import {
	examAge,
	examLoginFormData,
	examForgotFormData,
	examResetFormData,
	examPhoneData,
	examSignupAccountFormData,
	examSignupUserBasicFormData,
	examSignupIdUploadFormData,
	examSignupCreditCardFormData,
	examRentalFormData,
} from 'util/exam';
import {
	clearLoginFormData,
	clearForgotFormData,
	clearResetFormData,
	clearSignupAccountFormData,
	clearSignupUserBasicFormData,
	clearSignupIdUploadFormData,
	clearSignupCreditCardFormData,
} from 'util/clear';
import storage from 'util/storage';
import { isExist, isEmpty } from 'util/helper';

import { closeModal, openModal } from 'models/modal';
import {
	getUserProcess,
	getUser,
	checkCreditCard,
	clearUser,
	checkUserUsageRight,
} from 'models/user';
import { pushRoute } from 'models/routing';
import { combineLocalCartWithAuth } from 'models/shop';
import { clearAfterSales } from 'models/afterSales';

const defaultServiceData = {
	name: '',
	access_token: '',
};

export const setLogin = createAction('SET_LOGIN');
export const setLogout = createAction('SET_LOGOUT');
export const clearToken = createAction('CLEAR_TOKEN');

export const updateAccessToken = createAction('UPDATE_ACCESS_TOKEN', token => {
	storage.setItem('token', JSON.stringify(token));

	return token;
});

const updateSSOToken = createAction('UPDATE_SSO_TOKEN', token => {
	storage.setItem('bs-token', JSON.stringify(token));

	return token;
});

const updateService = createAction('UPDATE_SERVICE', data => ({
	data,
}));

export const updateForm = createAction('UPDATE_FORM', ({ type, key, data }) => ({
	type,
	key,
	data,
}));

const validateForm = createAction('VALIDATE_FORM', (type, key, valid, error) => ({
	type,
	key,
	valid,
	error,
}));

const clearFormError = createAction('CLEAR_FORM_ERROR', type => (_, getState) => {
	const {
		auth: {
			loginForm,
			forgotForm,
			resetForm,
			signupAccountForm,
			signupUserBasicForm,
			signupIdUploadForm,
			signupCreditCardForm,
		},
	} = getState();
	let clearData;

	if (type === 'loginForm') {
		clearData = clearLoginFormData(loginForm);
	} else if (type === 'forgotForm') {
		clearData = clearForgotFormData(forgotForm);
	} else if (type === 'resetForm') {
		clearData = clearResetFormData(resetForm);
	} else if (type === 'signupAccountForm') {
		clearData = clearSignupAccountFormData(signupAccountForm);
	} else if (type === 'signupUserBasicForm') {
		clearData = clearSignupUserBasicFormData(signupUserBasicForm);
	} else if (type === 'signupIdUploadForm') {
		clearData = clearSignupIdUploadFormData(signupIdUploadForm);
	} else if (type === 'signupCreditCardForm') {
		clearData = clearSignupCreditCardFormData(signupCreditCardForm);
	}

	return {
		type,
		data: clearData,
	};
});

const clearFormErrorByFormType = formType => dispatch => {
	if (['login', 'reset', 'forgot', 'signupAccount'].includes(formType)) {
		dispatch(
			clearFormError(
				{
					login: 'loginForm',
					reset: 'resetForm',
					forgot: 'forgotForm',
					signupAccount: 'signupAccountForm',
				}[formType],
			),
		);
	}
};

export const normalLogin = createAction('NORMAL_LOGIN', () => async (dispatch, getState) => {
	dispatch(clearFormError('loginForm'));

	const {
		routing: { pathname },
		auth: { loginForm },
	} = getState();

	const checkData = examLoginFormData(loginForm);

	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('loginForm', key, false, key === 'password' ? '未填寫帳號或密碼' : '')),
		);
		return null;
	}

	const { status, message, data } = await wrapFetch('auth/login', {
		method: 'POST',
		body: JSON.stringify({ mobile: loginForm.phone.value, password: loginForm.password.value }),
	});

	if (status !== 200 && status !== 201) {
		if (message === 'The 手機 is not a mobile number') {
			dispatch(validateForm('loginForm', 'phone', false, '手機格式錯誤'));
		} else if (message === 'The user login failed five times.') {
			dispatch(validateForm('loginForm', 'phone', false, ''));
			dispatch(
				validateForm('loginForm', 'password', false, '登入失敗達五次，請於五分鐘後再次嘗試登入'),
			);
		} else {
			dispatch(validateForm('loginForm', 'phone', false, ''));
			dispatch(validateForm('loginForm', 'password', false, '帳號或密碼輸入錯誤'));
		}

		throw new Error(message);
	}

	dispatch(updateAccessToken(data.data));
	dispatch(combineLocalCartWithAuth());
	await dispatch(getUserProcess());

	if (pathname === `/login`) {
		dispatch(pushRoute({ pathname: '/' }));
	} else if (pathname.includes('VesShare')) {
		await dispatch(clearFormError('vesShareForm'));
		const {
			rental: { vesShareForm },
		} = getState();

		const checkData2 = examRentalFormData(vesShareForm);
		if (!checkData2.value) {
			return null;
		}
		return dispatch(checkUserUsageRight()).then(({ value: { success } }) => {
			if (success) {
				dispatch(
					pushRoute({ search: qs.stringify({ step: 'checkout' }, { addQueryPrefix: true }) }),
				);
				dispatch(closeModal({ category: 'auth' }));
			}
		});
	}

	dispatch(closeModal({ category: 'auth' }));

	return null;
});

export const loadAuthToken = createAction('LOAD_AUTH_TOKEN', data => ({ data }));

export const sendResetPassword = createAction(
	'SEND_RESET_PASSWORD',
	() => async (dispatch, getState) => {
		dispatch(clearFormError('forgotForm'));

		const {
			auth: { forgotForm },
		} = getState();

		const checkData = examForgotFormData(forgotForm);
		if (!checkData.value) {
			checkData.notValid.map(key =>
				dispatch(validateForm('forgotForm', key, false, '此欄位不得空白')),
			);
			return;
		}

		const { status, message } = await wrapFetch('auth/forgetPassword', {
			method: 'POST',
			body: JSON.stringify({ email: forgotForm.email.value }),
		});

		if (status !== 200 && status !== 201) {
			if (status === 404) {
				dispatch(
					updateForm({
						type: 'forgotForm',
						key: 'email',
						data: {
							valid: false,
							error: '寄送失敗，此郵件還沒註冊',
						},
					}),
				);
			} else if (message === 'user no need reset password') {
				dispatch(
					updateForm({
						type: 'forgotForm',
						key: 'email',
						data: {
							valid: false,
							error: '您尚未設定密碼，請使用原快速註冊方式登入即可。',
						},
					}),
				);
			} else if (status === 429 && message === 'Too Many Attempts.') {
				dispatch(
					updateForm({
						type: 'forgotForm',
						key: 'email',
						data: {
							valid: false,
							error: '已達寄發 email 上限，請稍後後再嘗試',
						},
					}),
				);
			} else {
				dispatch(
					updateForm({
						type: 'forgotForm',
						key: 'email',
						data: {
							valid: false,
							error: '寄送失敗，您輸入的信箱有誤',
						},
					}),
				);
			}

			throw new Error(message);
		}

		dispatch(
			openModal({ category: 'toast', type: 'success', data: { message: '重設密碼信件已寄出' } }),
		);
	},
);

export const resetPassword = createAction('RESET_PASSWORD', () => async (dispatch, getState) => {
	dispatch(clearFormError('resetForm'));

	const {
		auth: { resetForm },
		routing: { search },
	} = getState();
	const { email_code: emailCode = '' } = qs.parse(search, { ignoreQueryPrefix: true });

	const checkData = examResetFormData(resetForm);
	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('resetForm', key, false, '此欄位不得空白')),
		);
		return;
	}

	if (resetForm.newPassword.value.length < 8) {
		dispatch(
			updateForm({
				type: 'resetForm',
				key: 'newPassword',
				data: {
					valid: false,
					error: '密碼少於8碼',
				},
			}),
		);
		return;
	}

	// 至少8個數字和英文
	const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
	if (!regex.test(resetForm.newPassword.value)) {
		dispatch(
			updateForm({
				type: 'resetForm',
				key: 'newPassword',
				data: {
					valid: false,
					error: '密碼格式不符',
				},
			}),
		);
		return;
	}

	if (resetForm.newPassword.value !== resetForm.repeatPassword.value) {
		dispatch(
			updateForm({
				type: 'resetForm',
				key: 'repeatPassword',
				data: {
					valid: false,
					error: '輸入密碼不相同',
				},
			}),
		);
		return;
	}

	const { status, message } = await wrapFetch('auth/resetPassword', {
		method: 'POST',
		body: JSON.stringify({ email_code: emailCode, password: resetForm.newPassword.value }),
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	dispatch(openModal({ category: 'toast', type: 'success', data: { message: '密碼修改成功' } }));
});

export const isGoogleLibraryLoaded = createAction('IS_GOOGLE_LIBRARY_LOADED', isLoaded => isLoaded);

const loginGoogle = createAction('LOGIN_GOOGLE', credential => async (dispatch, getState) => {
	const { status, message, data: d } = await wrapFetch('auth/login/google/callback', {
		method: 'POST',
		body: JSON.stringify({ access_token: credential, site: 'website' }),
	});

	if (status !== 200 && status !== 201) {
		dispatch(openModal({ category: 'toast', type: 'failed', data: { message } }));
		throw new Error(message);
	}

	const { data } = d;

	if (data.is_new_user) {
		dispatch(
			openModal({
				category: 'auth',
				type: 'signup',
			}),
		);
		dispatch(updateService({ name: 'google', access_token: credential }));
		dispatch(
			updateForm({
				type: 'signupAccountForm',
				key: 'email',
				data: {
					value: data.email,
				},
			}),
		);
	} else {
		const {
			routing: { pathname },
		} = getState();

		dispatch(updateAccessToken(data));
		dispatch(combineLocalCartWithAuth());
		await dispatch(getUserProcess());

		if (pathname === `/login`) {
			dispatch(pushRoute({ pathname: '/' }));
		} else if (pathname.includes('VesShare')) {
			await dispatch(clearFormError('vesShareForm'));
			const {
				rental: { vesShareForm },
			} = getState();

			const checkData2 = examRentalFormData(vesShareForm);
			if (!checkData2.value) {
				return null;
			}
			return dispatch(checkUserUsageRight()).then(({ value: { success } }) => {
				if (success) {
					dispatch(
						pushRoute({ search: qs.stringify({ step: 'checkout' }, { addQueryPrefix: true }) }),
					);
					dispatch(closeModal({ category: 'auth' }));
				}
			});
		}

		dispatch(closeModal({ category: 'auth' }));

		return Promise.resolve({ success: false });
	}

	return Promise.resolve({ success: true, email: data.email });
});

const bindGoogle = createAction('BIND_GOOGLE', credential => async dispatch => {
	const { status, message } = await wrapAuthFetch('auth/login/google/callback', {
		method: 'POST',
		body: JSON.stringify({ access_token: credential, site: 'website' }),
	});

	if (status !== 200 && status !== 201) {
		dispatch(openModal({ category: 'toast', type: 'failed', data: { message } }));
		throw new Error(message);
	}

	await dispatch(getUser());

	return Promise.resolve({ success: true });
});

const facebookNativeLogin = () =>
	new Promise((resolve, reject) => {
		window.FB.login(
			response => {
				if (response.status === 'connected') {
					resolve(response);
				} else {
					reject(response);
				}
			},
			{ scope: 'public_profile,email' },
		);
	});

const loginFacebook = createAction('LOGIN_FACEBOOK', () => async (dispatch, getState) => {
	const auth = await facebookNativeLogin();

	const { accessToken } = auth.authResponse;

	const { status, message, data: d } = await wrapFetch('auth/login/facebook/callback', {
		method: 'POST',
		body: JSON.stringify({ access_token: accessToken }),
	});

	if (status !== 200 && status !== 201) {
		dispatch(openModal({ category: 'toast', type: 'failed', data: { message } }));
		throw new Error(message);
	}

	const { data } = d;

	if (data.is_new_user) {
		dispatch(
			openModal({
				category: 'auth',
				type: 'signup',
			}),
		);
		dispatch(updateService({ name: 'facebook', access_token: accessToken }));
		dispatch(
			updateForm({
				type: 'signupAccountForm',
				key: 'email',
				data: {
					value: data.email,
				},
			}),
		);
	} else {
		const {
			routing: { pathname },
		} = getState();
		dispatch(updateAccessToken(data));
		dispatch(combineLocalCartWithAuth());
		await dispatch(getUserProcess());

		if (pathname === `/login`) {
			dispatch(pushRoute({ pathname: '/' }));
		} else if (pathname.includes('VesShare')) {
			await dispatch(clearFormError('vesShareForm'));
			const {
				rental: { vesShareForm },
			} = getState();

			const checkData2 = examRentalFormData(vesShareForm);
			if (!checkData2.value) {
				return null;
			}
			return dispatch(checkUserUsageRight()).then(({ value: { success } }) => {
				if (success) {
					dispatch(
						pushRoute({ search: qs.stringify({ step: 'checkout' }, { addQueryPrefix: true }) }),
					);
					dispatch(closeModal({ category: 'auth' }));
				}
			});
		}

		dispatch(closeModal({ category: 'auth' }));

		return Promise.resolve({ success: false });
	}

	return Promise.resolve({ success: true, email: data.email });
});

const bindFacebook = createAction('BIND_FACEBOOK', () => async dispatch => {
	const auth = await facebookNativeLogin();

	const { accessToken } = auth.authResponse;

	const { status, message } = await wrapAuthFetch('auth/login/facebook/callback', {
		method: 'POST',
		body: JSON.stringify({ access_token: accessToken }),
	});

	if (status !== 200 && status !== 201) {
		dispatch(openModal({ category: 'toast', type: 'failed', data: { message } }));
		throw new Error(message);
	}

	await dispatch(getUser());

	return Promise.resolve({ success: true });
});

const { APPLE_CLIENT_ID, LOGIN_REDIRECT_URL } = process.env;

const loginApple = createAction('LOGIN_APPLE', () => async (dispatch, getState) => {
	await window.AppleID.auth.init({
		clientId: APPLE_CLIENT_ID,
		scope: 'name email',
		redirectURI: LOGIN_REDIRECT_URL,
		state: '[STATE]',
		usePopup: true,
	});

	const response = await window.AppleID.auth.signIn();

	const { status, message, data: d } = await wrapFetch('auth/login/apple/callback', {
		method: 'POST',
		body: JSON.stringify({
			code: response.authorization.code,
			id_token: response.authorization.id_token,
		}),
	});

	if (status !== 200 && status !== 201) {
		dispatch(openModal({ category: 'toast', type: 'failed', data: { message } }));
		throw new Error(message);
	}

	const { data } = d;

	if (data.is_new_user) {
		dispatch(
			openModal({
				category: 'auth',
				type: 'signup',
			}),
		);
		dispatch(updateService({ name: 'apple', access_token: response.authorization.id_token }));
		dispatch(
			updateForm({
				type: 'signupAccountForm',
				key: 'email',
				data: {
					value: data.email,
				},
			}),
		);
	} else {
		const {
			routing: { pathname },
		} = getState();
		dispatch(updateAccessToken(data));
		dispatch(combineLocalCartWithAuth());
		await dispatch(getUserProcess());

		if (pathname === `/login`) {
			dispatch(pushRoute({ pathname: '/' }));
		} else if (pathname.includes('VesShare')) {
			await dispatch(clearFormError('vesShareForm'));
			const {
				rental: { vesShareForm },
			} = getState();

			const checkData2 = examRentalFormData(vesShareForm);
			if (!checkData2.value) {
				return null;
			}
			return dispatch(checkUserUsageRight()).then(({ value: { success } }) => {
				if (success) {
					dispatch(
						pushRoute({ search: qs.stringify({ step: 'checkout' }, { addQueryPrefix: true }) }),
					);
					dispatch(closeModal({ category: 'auth' }));
				}
			});
		}

		dispatch(closeModal({ category: 'auth' }));

		return Promise.resolve({ success: false });
	}

	return Promise.resolve({ success: true, email: data.email });
});

export const logout = createAction('LOGOUT', () => async dispatch => {
	const { status, message } = await wrapAuthFetch('auth/logout', {
		method: 'POST',
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	storage.removeItem('token');
	storage.removeItem('cart');
	dispatch(pushRoute({ pathname: '/' }));
	dispatch(clearToken());
	dispatch(clearUser());
	dispatch(clearAfterSales());
	dispatch(setLogout());
});

const setSignupStepStatus = createAction('SET_SIGNUP_STEP_STATUS', type => ({
	type,
}));

const resetSignupStep = createAction('RESET_SIGNUP_STEP');

const setSignupStep = createAction('SET_SIGNUP_STEP', step => step);

const setMobieVerifySuspended = createAction('SET_SIGNUP_MOBILE_VERIFY_SUSPENDED', data => data);

export const verifyPhone = createAction('VERIFY_NUMBER', () => async (dispatch, getState) => {
	dispatch(clearFormError('signupAccountForm'));

	const {
		auth: { signupAccountForm },
	} = getState();

	const checkData = examPhoneData(signupAccountForm);
	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('signupAccountForm', key, false, '此欄位不得空白')),
		);
		return;
	}

	if (!/^09[0-9]{8}$/.test(signupAccountForm.phone.value)) {
		dispatch(validateForm('signupAccountForm', 'phone', false, '手機號碼格式不正確'));
		return;
	}

	const { status, message } = await wrapFetch('auth/mobile', {
		method: 'POST',
		body: JSON.stringify({ mobile: signupAccountForm.phone.value }),
	});

	if (status !== 200 && status !== 201) {
		if (message === 'this mobile already exist') {
			dispatch(
				updateForm({
					type: 'signupAccountForm',
					key: 'phone',
					data: {
						error: '手機號碼已註冊',
					},
				}),
			);
		} else if (message === 'plz request after 60 seconds') {
			dispatch(
				updateForm({
					type: 'signupAccountForm',
					key: 'phone',
					data: {
						error: '請稍等再重新寄送驗證碼',
					},
				}),
			);
		}
		throw new Error(message);
	}

	dispatch(setMobieVerifySuspended(true));
});

export const verifyEmail = createAction('VERIFY_EMAIL', () => async (dispatch, getState) => {
	const {
		routing: { search },
		auth: {
			token: { access_token: accessToken },
		},
	} = getState();
	const { email_code: code } = qs.parse(search, { ignoreQueryPrefix: true });

	if (isEmpty(code)) {
		dispatch(pushRoute({ pathname: '/' }));
	}

	const { status, message } = await wrapFetch('auth/verifyEmail', {
		method: 'POST',
		body: JSON.stringify({ email_code: code }),
	});

	if (status !== 200 && status !== 201) {
		dispatch(pushRoute({ pathname: '/' }));
		throw new Error(message);
	}

	await dispatch(
		openModal({
			category: 'auth',
			type: 'emailVerify',
		}),
	);

	if (isExist(accessToken)) {
		await dispatch(getUser());
	}
});

const setupUserBasicForm = createAction(
	'SETUP_USER_BASIC_FORM',
	() => async (dispatch, getState) => {
		const {
			user: { data },
		} = getState();

		if (data.name) {
			dispatch(
				updateForm({
					type: 'signupUserBasicForm',
					key: 'name',
					data: { value: data.name },
				}),
			);
		}
		if (data.gender) {
			dispatch(
				updateForm({
					type: 'signupUserBasicForm',
					key: 'gender',
					data: { value: data.gender },
				}),
			);
		}
		if (data.birth) {
			const [year, month, day] = data.birth.split('-');
			dispatch(
				updateForm({
					type: 'signupUserBasicForm',
					key: 'birthday',
					data: { year: parseInt(year, 10), month: parseInt(month, 10), day: parseInt(day, 10) },
				}),
			);
		}
		if (data.id_number) {
			dispatch(
				updateForm({
					type: 'signupUserBasicForm',
					key: 'idNumber',
					data: { value: data.id_number },
				}),
			);
		}
		if (data.city_id) {
			dispatch(
				updateForm({
					type: 'signupUserBasicForm',
					key: 'address',
					data: { county: data.city_id },
				}),
			);
		}
		if (data.district_id) {
			dispatch(
				updateForm({
					type: 'signupUserBasicForm',
					key: 'address',
					data: { district: data.district_id },
				}),
			);
		}
		if (data.address) {
			dispatch(
				updateForm({
					type: 'signupUserBasicForm',
					key: 'address',
					data: { other: data.address },
				}),
			);
		}
	},
);

export const getGuideUrlByRegisterEvent = createAction(
	'GET_GUIDE_URL_BY_REGISTER_EVENT',
	() => async () => {
		const regEvent = JSON.parse(storage.getItem('regEvent'));

		const { status, data } = await wrapFetch('registerEvents/getGuideUrlByRegisterEvent', {
			method: 'POST',
			body: JSON.stringify({
				register_event: regEvent,
			}),
		});

		if (status !== 200 || !data) {
			throw new Error();
		}

		return data.data.guide_url;
	},
);

export const signup = createAction('SIGNUP', isFastLogin => async (dispatch, getState) => {
	dispatch(clearFormError('signupAccountForm'));

	const {
		auth: { signupAccountForm, service },
		routing: { search },
	} = getState();

	const regEvent = JSON.parse(storage.getItem('regEvent'));
	const { code } = qs.parse(search, { ignoreQueryPrefix: true });

	const checkData = examSignupAccountFormData(signupAccountForm, isFastLogin);
	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('signupAccountForm', key, false, '此欄位不得空白')),
		);
		throw new Error();
	}

	// TODO: 信箱格式

	let validStatus = 0;

	if (!/^09[0-9]{8}$/.test(signupAccountForm.phone.value)) {
		dispatch(validateForm('signupAccountForm', 'phone', false, '手機號碼格式不正確'));
		validStatus = 400;
	}

	if (!/^[0-9]{6}$/.test(signupAccountForm.phoneVerify.value)) {
		dispatch(validateForm('signupAccountForm', 'phoneVerify', false, '驗證碼格式不正確'));
		validStatus = 400;
	}

	if (
		!isFastLogin &&
		!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(signupAccountForm.password.value)
	) {
		dispatch(validateForm('signupAccountForm', 'password', false, '密碼格式不正確'));
		validStatus = 400;
	}

	if (!isFastLogin && signupAccountForm.password.value !== signupAccountForm.repeatPassword.value) {
		dispatch(validateForm('signupAccountForm', 'repeatPassword', false, '確認密碼和密碼不符合'));
		validStatus = 400;
	}

	if (
		!isFastLogin &&
		!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			signupAccountForm.email.value,
		)
	) {
		dispatch(validateForm('signupAccountForm', 'email', false, '電子信箱格式不正確'));
		validStatus = 400;
	}

	if (validStatus === 400) {
		throw new Error();
	}

	// API: POST signup api
	const { status, message, data } = await wrapFetch('auth/signup', {
		method: 'POST',
		body: JSON.stringify({
			mobile: signupAccountForm.phone.value,
			mobile_code: signupAccountForm.phoneVerify.value,
			email: signupAccountForm.email.value,
			is_vmod: signupAccountForm.vmodMember.value,
			...(!signupAccountForm.referralCode.value && regEvent && { reg_event: regEvent }),
			...(code && { reg_event: code }),
			...(signupAccountForm.referralCode.value && {
				referral_code: signupAccountForm.referralCode.value,
			}),
			...(signupAccountForm.invitationCode.value && {
				mgm_code: signupAccountForm.invitationCode.value,
			}),
			...(isFastLogin
				? { service: service.name, token: service.access_token }
				: { password: signupAccountForm.password.value }),
		}),
	});

	if (status !== 200 && status !== 201) {
		if (message === 'already has another user used this email.') {
			dispatch(
				updateForm({
					type: 'signupAccountForm',
					key: 'email',
					data: {
						error: '已存在此 email 帳號',
					},
				}),
			);
		} else if (message === 'The email must be a valid email address.') {
			dispatch(
				updateForm({
					type: 'signupAccountForm',
					key: 'email',
					data: {
						error: '您輸入的信箱有誤',
					},
				}),
			);
		} else if (message === 'cannot find the mobile code.') {
			dispatch(
				updateForm({
					type: 'signupAccountForm',
					key: 'phoneVerify',
					data: {
						error: '驗證碼錯誤',
					},
				}),
			);
		} else if (message === 'vmod member not found') {
			dispatch(
				updateForm({
					type: 'signupAccountForm',
					key: 'vmodMember',
					data: {
						value: false,
						error:
							'系統無法查詢到您的 VMOD 會員資料，如欲了解 VMOD 會員，請點選上方「?」按鈕，謝謝。',
					},
				}),
			);
		} else if (message === 'cannot find the referral code.') {
			dispatch(
				updateForm({
					type: 'signupAccountForm',
					key: 'referralCode',
					data: {
						error: '推薦代碼有誤',
						valid: false,
					},
				}),
			);
		}
		throw new Error(message);
	}

	dispatch(updateAccessToken(data.data));
	dispatch(combineLocalCartWithAuth());
	dispatch(updateService(defaultServiceData));
	await dispatch(setSignupStepStatus('signup'));
	await dispatch(setSignupStep(2));
	await dispatch(getUser());
	await dispatch(setupUserBasicForm());
	await dispatch(setLogin());
	await dispatch(getGuideUrlByRegisterEvent());
	storage.removeItem('regEvent');
});

export const sendUserBasic = createAction('SEND_BASIC', () => async (dispatch, getState) => {
	dispatch(clearFormError('signupUserBasicForm'));

	const {
		auth: { signupUserBasicForm },
	} = getState();

	let validStatus = true;

	// TODO: 年滿 18 歲、身分證格式
	if (
		!examAge(
			signupUserBasicForm.birthday.year,
			signupUserBasicForm.birthday.month,
			signupUserBasicForm.birthday.day,
		)
	) {
		dispatch(validateForm('signupUserBasicForm', 'birthday', false, '年齡未滿 18 歲'));
		validStatus = false;
	}

	// TODO: 身分證格式
	if (!/^[A-Z][A-Z0-9][0-9]{8}$/.test(signupUserBasicForm.idNumber.value)) {
		dispatch(
			validateForm('signupUserBasicForm', 'idNumber', false, '身分證字號（永居證）格式不正確'),
		);
		validStatus = false;
	}

	if (
		signupUserBasicForm.emergencyContactMobile.value &&
		!/^09[0-9]{8}$/.test(signupUserBasicForm.emergencyContactMobile.value)
	) {
		dispatch(
			validateForm('signupUserBasicForm', 'emergencyContactMobile', false, '手機號碼格式不正確'),
		);
		validStatus = false;
	}

	if (!validStatus) {
		throw new Error();
	}

	const checkData = examSignupUserBasicFormData(signupUserBasicForm);
	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('signupUserBasicForm', key, false, '此欄位不得空白')),
		);
		throw new Error();
	}

	// API: POST signup api

	const { status, message } = await wrapAuthFetch('me', {
		method: 'PUT',
		body: JSON.stringify({
			name: signupUserBasicForm.name.value,
			gender: signupUserBasicForm.gender.value, // 男性(MALE)| 女性(FEMALE)|其他(OTHER)
			birth: dayjs({
				y: signupUserBasicForm.birthday.year,
				M: signupUserBasicForm.birthday.month - 1,
				d: signupUserBasicForm.birthday.day,
			}).format('YYYY-MM-DD'),
			id_number: signupUserBasicForm.idNumber.value,
			city_id: signupUserBasicForm.address.county,
			district_id: signupUserBasicForm.address.district,
			address: signupUserBasicForm.address.other,
			emergency_contact_name: signupUserBasicForm.emergencyContactName.value,
			emergency_contact_mobile: signupUserBasicForm.emergencyContactMobile.value,
		}),
	});

	if (status !== 200 && status !== 201) {
		if (status === 422) {
			dispatch(
				validateForm('signupUserBasicForm', 'idNumber', false, '身分證字號（永居證）格式不正確'),
			);
		}
		throw new Error(message);
	}

	await dispatch(setSignupStepStatus('basic'));
	await dispatch(getUser());
	dispatch(setSignupStep(3));
});

export const sendIdUpload = createAction('SEND_UPLOAD', () => async (dispatch, getState) => {
	dispatch(clearFormError('signupIdUploadForm'));

	const {
		auth: { signupIdUploadForm },
	} = getState();

	const checkData = examSignupIdUploadFormData(signupIdUploadForm);
	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('signupIdUploadForm', key, false, '此欄位不得空白')),
		);
		throw new Error();
	}

	const { status, message } = await wrapAuthFetch('identityCards', {
		method: 'PUT',
		body: JSON.stringify({
			id_card_front: signupIdUploadForm.idPhotos.front,
			id_card_back: signupIdUploadForm.idPhotos.back,
			drivers_license_type: signupIdUploadForm.driverLicenseType.value,
			drivers_license_front: signupIdUploadForm.driverLicensePhotos.front,
			drivers_license_back: signupIdUploadForm.driverLicensePhotos.back,
		}),
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	await dispatch(getUser());
	await dispatch(setSignupStepStatus('identity'));
	dispatch(setSignupStep(4));
});

export const sendCreditCard = createAction('SEND_CREDIT_CARD', () => async (dispatch, getState) => {
	dispatch(clearFormError('signupCreditCardForm'));

	const {
		auth: { signupCreditCardForm },
	} = getState();

	let validStatus = 0;

	if (!/^[0-9]{16}$/.test(signupCreditCardForm.creditCardNo.value)) {
		dispatch(validateForm('signupCreditCardForm', 'creditCardNo', false, '信用卡卡號格式不正確'));
		validStatus = 400;
	}

	if (!/^(0[1-9]|1[0-2])([0-9]{2})$/.test(signupCreditCardForm.creditCardExp.value)) {
		dispatch(validateForm('signupCreditCardForm', 'creditCardExp', false, '期限格式不正確'));
		validStatus = 400;
	} else if (dayjs(signupCreditCardForm.creditCardExp.value, 'MMYY').isBefore(dayjs(), 'month')) {
		dispatch(validateForm('signupCreditCardForm', 'creditCardExp', false, '信用卡期限已過期'));
		validStatus = 400;
	}

	if (!/^[0-9]{3}$/.test(signupCreditCardForm.creditCardCvc.value)) {
		dispatch(validateForm('signupCreditCardForm', 'creditCardCvc', false, '安全碼格式不正確'));
		validStatus = 400;
	}

	if (validStatus === 400) {
		throw new Error();
	}

	const checkData = examSignupCreditCardFormData(signupCreditCardForm);
	if (!checkData.value) {
		checkData.notValid.map(key =>
			dispatch(validateForm('signupCreditCardForm', key, false, '此欄位不得空白')),
		);
		throw new Error();
	}

	// API: POST credit card api

	const { status, message } = await wrapAuthFetch('creditCards', {
		method: 'PUT',
		body: JSON.stringify({
			card_no: signupCreditCardForm.creditCardNo.value,
			card_exp: `${signupCreditCardForm.creditCardExp.value.slice(
				-2,
			)}${signupCreditCardForm.creditCardExp.value.slice(0, 2)}`,
			card_cvc: signupCreditCardForm.creditCardCvc.value,
		}),
	});

	if (status === 400) {
		dispatch(
			validateForm(
				'creditCardForm',
				'creditCardNo',
				false,
				'信用卡綁定失敗，請檢查並重新輸入有效效期之信用卡資料。Vespa官方商城目前支援卡別為：VISA、Mastercard、JCB。',
			),
		);
		throw new Error(message);
	}

	if (status !== 200) {
		dispatch(validateForm('signupCreditCardForm', 'creditCardNo', false, '卡號或格式錯誤'));
		throw new Error(message);
	}

	await dispatch(setSignupStepStatus('creditCard'));
	await dispatch(checkCreditCard());
	dispatch(setSignupStep(5));
});

export const getSsoToken = createAction('GET_SSO_TOKEN', () => async (dispatch, getState) => {
	const {
		routing: { search },
	} = getState();

	const { account = '', code = '' } = qs.parse(search, { ignoreQueryPrefix: true });

	const { status, data, message } = await wrapFetchSSO('sso/token', {
		method: 'POST',
		body: JSON.stringify({
			code,
			account,
		}),
	});

	if (status !== 200 && status !== 201) {
		throw new Error(message);
	}

	dispatch(updateSSOToken({ access_token: data.data.token }));
});

export const setIsWebview = createAction('IS_WEBVIEW', result => result);

export const defaultLoginFormData = {
	phone: { value: '', valid: true, error: '' },
	password: { value: '', valid: true, error: '' },
	loading: false,
	error: '',
};

export const defaultForgotFormData = {
	email: { value: '', valid: true, error: '' },
	loading: false,
};

export const defaultResetFormData = {
	newPassword: { value: '', valid: true, error: '' },
	repeatPassword: { value: '', valid: true, error: '' },
	loading: false,
};

const defaultSignupAccountFormData = {
	phone: { value: '', verify: false, suspended: false, valid: true, error: '' },
	phoneVerify: { value: '', valid: true, error: '' },
	password: { value: '', valid: true, error: '' },
	repeatPassword: { value: '', valid: true, error: '' },
	email: { value: '', valid: true, error: '' },
	invitationCode: { value: '', valid: true, error: '' },
	referralCode: { value: '', valid: true, error: '' },
	vmodMember: { value: false, valid: true, error: '' },
	loading: false,
};

const defaultSignupUserBasicData = {
	name: { value: '', valid: true, error: '' },
	gender: { value: '', valid: true, error: '' },
	birthday: { year: null, month: null, day: null, valid: true, error: '' },
	idNumber: { value: '', valid: true, error: '' },
	address: { county: '', district: '', other: '', valid: true, error: '' },
	emergencyContactName: { value: '', valid: true, error: '' },
	emergencyContactMobile: { value: '', valid: true, error: '' },
	loading: false,
};

const defaultSignupIdUploadData = {
	idPhotos: { front: null, back: null, valid: true, error: '', loading: false, target: '' },
	driverLicenseType: { value: '', valid: true, error: '' },
	driverLicensePhotos: {
		front: null,
		back: null,
		valid: true,
		error: '',
		loading: false,
		target: '',
	},
	loading: false,
};

const defaultSignupCreditCardForm = {
	creditCardNo: { value: '', valid: true, error: '' },
	creditCardExp: { value: '', valid: true, error: '' },
	creditCardCvc: { value: '', valid: true, error: '' },
	loading: false,
};

const defaultSignupStepsStatus = {
	signup: false,
	basic: false,
	identity: false,
	creditCard: false,
	memberReview: false,
};

export const defaultTokenData = {
	access_token: '',
	expires_in: 0,
	refresh_token: '',
	token_type: '',
};

export const defaultSSOTokenData = {
	access_token: '',
};

const reducer = {
	auth: handleActions(
		{
			UPDATE_SERVICE: (state, action) => ({
				...state,

				service: action.payload.data,
			}),

			UPDATE_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			VALIDATE_FORM: (state, action) => ({
				...state,

				[action.payload.type]: {
					...state[action.payload.type],
					[action.payload.key]: {
						...state[action.payload.type][action.payload.key],
						valid: action.payload.valid,
						error: action.payload.error,
					},
				},
			}),

			CLEAR_FORM_ERROR: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			LOAD_AUTH_TOKEN: (state, action) => ({
				...state,

				token: action.payload.data,
			}),

			UPDATE_ACCESS_TOKEN: (state, action) => ({
				...state,

				token: action.payload,
			}),

			UPDATE_SSO_TOKEN: (state, action) => ({
				...state,

				ssoToken: action.payload,
			}),

			SET_SIGNUP_MOBILE_VERIFY_SUSPENDED: (state, action) => ({
				...state,

				signupAccountForm: {
					...state.signupAccountForm,
					phone: {
						...state.signupAccountForm.phone,
						suspended: action.payload,
					},
				},
			}),

			SET_SIGNUP_STEP_STATUS: (state, action) => ({
				...state,

				signupStepsStatus: {
					...state.signupStepsStatus,

					[action.payload.type]: true,
				},
			}),

			RESET_SIGNUP_STEP: state => ({
				...state,

				signupStepsStatus: defaultSignupStepsStatus,
				signupStep: 1,
			}),

			SET_SIGNUP_STEP: (state, action) => ({
				...state,

				signupStep: action.payload,
			}),

			GET_GUIDE_URL_BY_REGISTER_EVENT_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_GUIDE_URL_BY_REGISTER_EVENT_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				guideUrl: action.payload,
			}),

			SIGNUP_PENDING: state => ({
				...state,

				signupAccountForm: {
					...state.signupAccountForm,

					loading: true,
				},
			}),

			SIGNUP_FULFILLED: state => ({
				...state,

				signupAccountForm: {
					...state.signupAccountForm,

					loading: false,
				},
			}),

			SIGNUP_REJECTED: state => ({
				...state,

				signupAccountForm: {
					...state.signupAccountForm,

					loading: false,
				},
			}),

			SEND_BASIC_PENDING: state => ({
				...state,

				signupUserBasicForm: {
					...state.signupUserBasicForm,

					loading: true,
				},
			}),

			SEND_BASIC_FULFILLED: state => ({
				...state,

				signupUserBasicForm: {
					...state.signupUserBasicForm,

					loading: false,
				},
			}),

			SEND_BASIC_REJECTED: state => ({
				...state,

				signupUserBasicForm: {
					...state.signupUserBasicForm,

					loading: false,
				},
			}),

			SEND_UPLOAD_PENDING: state => ({
				...state,

				signupIdUploadForm: {
					...state.signupIdUploadForm,

					loading: true,
				},
			}),

			SEND_UPLOAD_FULFILLED: state => ({
				...state,

				signupIdUploadForm: {
					...state.signupIdUploadForm,

					loading: false,
				},
			}),

			SEND_UPLOAD_REJECTED: state => ({
				...state,

				signupIdUploadForm: {
					...state.signupIdUploadForm,

					loading: false,
				},
			}),

			SEND_CREDIT_CARD_PENDING: state => ({
				...state,

				signupCreditCardForm: {
					...state.signupCreditCardForm,

					loading: true,
				},
			}),

			SEND_CREDIT_CARD_FULFILLED: state => ({
				...state,

				signupCreditCardForm: {
					...state.signupCreditCardForm,

					loading: false,
				},
			}),

			SEND_CREDIT_CARD_REJECTED: state => ({
				...state,

				signupCreditCardForm: {
					...state.signupCreditCardForm,

					loading: false,
				},
			}),

			SET_LOGIN: state => ({
				...state,

				isLogin: true,
			}),

			SET_LOGOUT: state => ({
				...state,

				isLogin: false,
			}),
			CLEAR_TOKEN: state => ({
				...state,
				token: defaultTokenData,
			}),
			IS_GOOGLE_LIBRARY_LOADED: (state, action) => ({
				...state,
				isGoogleLibraryLoad: action.payload,
			}),
			IS_WEBVIEW: (state, action) => ({
				...state,
				isWebview: action.payload,
			}),
		},
		{
			loginForm: defaultLoginFormData,
			forgotForm: defaultForgotFormData,
			resetForm: defaultResetFormData,
			signupAccountForm: defaultSignupAccountFormData,
			signupUserBasicForm: defaultSignupUserBasicData,
			signupIdUploadForm: defaultSignupIdUploadData,
			signupCreditCardForm: defaultSignupCreditCardForm,

			signupStepsStatus: defaultSignupStepsStatus,
			signupStep: 1,

			token: defaultTokenData,
			isLogin: false,
			ssoToken: defaultSSOTokenData,
			service: defaultServiceData,
			guideUrl: '',
			isGoogleLibraryLoad: false,
			isWebview: false,
		},
	),
};

const selectAuth = createSelector(
	state => state.auth.token,
	state => state.auth.isLogin,
	state => state.auth.isGoogleLibraryLoad,
	state => state.auth.isWebview,
	(token, isLogin, isGoogleLibraryLoad, isWebview) => ({
		token,
		hasToken: isExist(token.access_token),
		isLogin,
		isGoogleLibraryLoad,
		isWebview,
	}),
);

export const useAuth = () =>
	useRedux(selectAuth, {
		loginGoogle,
		loginFacebook,
		loginApple,
		bindGoogle,
		bindFacebook,
		logout,
		isGoogleLibraryLoaded,
		setIsWebview,
	});

const selectGuideUrl = state => state.auth.guideUrl;

export const useGuideUrl = () =>
	useRedux(selectGuideUrl, {
		getGuideUrlByRegisterEvent,
	});

const selectLoginForm = state => state.auth.loginForm;

export const useLoginForm = () =>
	useRedux(selectLoginForm, {
		updateForm,
		normalLogin,
	});

const selectForgotForm = state => state.auth.forgotForm;

export const useForgotForm = () =>
	useRedux(selectForgotForm, {
		updateForm,
		sendResetPassword,
	});

const selectResetForm = state => state.auth.resetForm;

export const useResetForm = () =>
	useRedux(selectResetForm, {
		updateForm,
		resetPassword,
	});

const selectSignupAccountForm = state => state.auth.signupAccountForm;

export const useSignupAccountForm = () =>
	useRedux(selectSignupAccountForm, {
		updateForm,
		verifyPhone,
		setMobieVerifySuspended,
		signup,
	});

const selectSignupUserBasicForm = state => state.auth.signupUserBasicForm;

export const useSignupUserBasicForm = () =>
	useRedux(selectSignupUserBasicForm, {
		updateForm,
		sendUserBasic,
	});

const selectSignupIdUploadForm = state => state.auth.signupIdUploadForm;

export const useSignupIdUploadForm = () =>
	useRedux(selectSignupIdUploadForm, {
		updateForm,
		sendIdUpload,
	});

const selectSignupCreditCardForm = state => state.auth.signupCreditCardForm;

export const useSignupCreditCardForm = () =>
	useRedux(selectSignupCreditCardForm, {
		updateForm,
		sendCreditCard,
	});

const selectSignupStepsStatus = state => ({
	step: state.auth.signupStep,
	status: state.auth.signupStepsStatus,
});

export const useSignupStepsStatus = () =>
	useRedux(selectSignupStepsStatus, { resetSignupStep, setSignupStep });

export const useClearFormError = () =>
	useRedux(
		{},
		{
			clearFormError,
			clearFormErrorByFormType,
		},
	);

const selectSSOAuth = createSelector(
	state => state.auth.ssoToken,
	token => ({ token, isLogin: isExist(token.access_token) }),
);

export const useSSOAuth = () =>
	useRedux(selectSSOAuth, {
		getSsoToken,
	});

export default { reducer };
